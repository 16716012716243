'use strict';

(function() {
  function ReportResource($resource) {
    return $resource('api/v1/reports/:id/:controller/:page/:limit/:sortBy/:order', {
      id: '@_id'
    }, {
      
      addReport: {
        method: 'POST',
      },
      getForBladeFindings: {
        method: 'POST',
        params: {
          controller: 'get-for-blade-findings'
        }
      },
      getForBladeRepairs: {
        method: 'POST',
        params: {
          controller: 'get-for-blade-repairs'
        }
      }

    });
  }

  angular.module('windmanagerApp')
    .factory('Report', ReportResource);
})();
